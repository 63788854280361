&.radio {
    input {
        width: auto;
        border-radius: 50%;
        cursor: pointer;
    }
    &:checked {
        &:before {
            color: $text-default;
        }
    }
    &:focus-within {
        box-shadow: none;
    }
    &:disabled {
        cursor: not-allowed;
        + label {
            color: $grey-600;
            cursor: not-allowed;
        }
    }

    label {
        width: auto;
        color: $text-default;
        cursor: pointer;
        margin-bottom: 0px;
    }

    &.required {
        label::after {
            content: "";
        }
        label:first-child::after {
            content: "*";
        }
    }
}
