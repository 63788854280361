
&.articles{
    grid-template-columns: repeat(4, 1fr);

    @media only screen and (max-width : 991px) {
        grid-template-columns: repeat(2, 1fr);    
    }
      
    @media only screen and (max-width : 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
    @import "component.list.articles.overview";
    @import "component.list.articles.accordion";
}
