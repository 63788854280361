&.page {

    ul,
    ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .image-container {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 360px;
        border-radius: 3px;
        cursor: zoom-in;

        &.placeholder {
            background-size: 20%;
            background-color: $white;
        }
    }

    .small-image {
        height: 120px;
        width: 100%;

        &.placeholder {
            background-size: 40%;
        }
    }

    .detail {
        padding: 10px 120px;

        h1 {
            margin-top: 30px;
        }

        ul {
            li {
                span {
                    display: block;

                    &.k {
                        color: $gray-600;
                        font-size: 14px;
                        font-weight: 300;
                        margin-top: 10px;
                    }

                    &.v {
                        color: $text-default;
                        font-size: 18px;
                        font-weight: 300;

                        &.big {
                            font-weight: 700;
                            font-size: 24px;
                        }

                        &.biggest {
                            font-weight: 700;
                            font-size: 32px;
                        }

                        &.warning {
                            color: $red;
                        }
                    }
                }

                &.highlight {
                    margin-top: 60px;
                    padding: 10px 20px 30px;
                    background-color: $critical;
                    border-radius: 5px;

                    span.k {
                        font-weight: 500;
                        font-size: 20px;
                        color: $white;
                    }

                    span.v {
                        color: $white;
                    }

                    ul.services {
                        display: flex;

                        li {
                            flex: 1 1 0px;

                            span.k {
                                font-weight: 100;
                                color: $critical-subdued;
                                font-size: 12px;
                                margin-top: 0;
                            }
                        }
                    }

                    .button {
                        margin-top: 20px;
                    }

                    &.payment {
                        &.complete {
                            background-color: lightgreen;
                        }

                        &.publishing {
                            margin-top:40px;
                            margin-bottom: 0px;
                            border-radius: 10px 10px 0 0;
                        }

                        &.transfer {
                            margin-top:0px;
                            margin-bottom: 40px;
                            border-radius: 0 0 10px 10px;
                        }

                        span.v {
                            font-size: 14px;
                            margin-top: 5px;
                        }

                        i.fa {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        p {
            margin-top: 30px;
            text-transform: uppercase;
        }

        p:last-of-type {
            margin-bottom: 30px;
        }

        a:not(.button) {
            text-decoration: underline;
        }
    }

    .d-flex {
        gap: 20px;
    }

    .breadcrumb-container {
        text-align: left;
        text-transform: uppercase;
        background-color: transparent;
        opacity: 1;
        margin-bottom: 15px;
    }

    .vehicle-card {
        text-align: center;
        padding-top: 80px;

        h3 {
            margin-bottom: 20px;
        }

        .description {
            font-size: 21px;
            font-weight: 100;
            padding-bottom: 50px;

            &:empty {
                display: none;
            }
        }

        ul.tab-pane {
            display: flex;

            li {
                flex: 1 1 0px;

                span {
                    display: block;

                    &.k {
                        color: $gray-600;
                        font-size: 14px;
                        font-weight: 300;
                        margin-top: 10px;
                    }

                    &.v {
                        color: $text-default;
                        font-size: 18px;
                        font-weight: 300;

                        &.big {
                            font-weight: 700;
                            font-size: 24px;
                        }

                        &.biggest {
                            font-weight: 700;
                            font-size: 32px;
                        }

                        &.warning {
                            color: $red;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .vehicle-card ul.tab-pane {
            display: block;
        }

        .detail {
            padding: 10px 40px;
        }
    }
}