.create {
    li {
        font-size: 16px;
        font-weight: 700;
        color: $grey-600;
    }

    ol {
        &.step {
            margin-top: 35px;
            margin-bottom: 0px;
            width: 100%;
            list-style: none;
            background-color: transparent;
            display: inline-flex;
            justify-content: space-between;
            padding: 0px;
            gap: 20px;

            li {
                border-bottom: 3px solid $grey-600;
                width: 100%;
                line-height: 15px;
                padding-bottom: 10px;
                font-weight: 400;

                &.active {
                    border-bottom: 3px solid $critical;
                    color: $text-default;
                    font-weight: 600;
                    color:$critical;
                }
            }

        }
    }

    label,
    p:not(.stepdescription) {
        //text-transform: uppercase;
    }

    .toggle {
        margin: 50px;
    }

    .card .button {
        display: none;
    }
}