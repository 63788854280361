&.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px;
    border:0.5px solid $grey-800;

    tr {
        td {
            border-top:0.5px solid $gray-600;
            &.k {
                font-weight: 100;
            }
            &.v {
                font-weight: 700;
                color:$text-default
            }
        }

        &:first-child {
            td {
                border-top:0;
            }
        }

        &.cut {
            td {
                border-bottom:2px solid $gray-600;
            }
        }
    }
}