&.select {
    flex-direction: column;

    select {
        width: 100%;
        background-color: $white;
        color:$text-default;
        &:focus,
        &:focus-visible{
            outline: none;
            box-shadow: 3px 3px 40px -4px rgba(41, 45, 82, 0.05),
            0px 0px 0px 4px rgba(224, 226, 244, 0.5);
        }
        
        .option{
            color: $text-default;
            cursor: pointer;
        }
        .default{
            color: $grey-600;
        }
        .no-default{
            &:hover{
                background-color: $surface-highlight;
            }
        }
    }
}
