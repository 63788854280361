&.toggle {
    margin: auto;
    display: flex;
    width: 100%;
    label.toggle {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 34px;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: $critical;

                &:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                    background-color: $white;
                }
            }

            &:focus + .slider {
                box-shadow: 0 0 1px #2196f3;
            }
            &:disabled {
                + .slider {
                    cursor: not-allowed;
                    opacity: 0.6;
                }
                ~ label {
                    cursor: not-allowed;
                    color: $grey-600;
                }
            }
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $grey-700;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            width: 60px;

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: $grey-600;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            &.round {
                border-radius: 34px;

                &:before {
                    border-radius: 50%;
                }
            }
        }
        .text {
            position: absolute;
            left: 70px;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            color: $text-default;
            cursor: pointer;
        }
    }
}
