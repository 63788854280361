.contrast {
    color: $white;
    background-color: $text-default;
    .step {
        .steptitle {
            color: $white;
        }
    }
    .form-element {
        label {
            color: $white;
        }
    }
    input:not([type="checkbox"], [type="radio"]) {
        background-color: $surface-critical-subdued;
        color: $grey-600;
        &:read-only {
            background-color: $surface-critical-subdued;
        }
    }
    .input-group {
        background-color: $surface-critical-subdued;
    }
    .input-group-text {
        background-color: $surface-critical-subdued;
        .fa-magnifying-glass {
            color: $white;
        }
    }
    input[type="checkbox"] {
        &:before {
            border: solid $white 1px;
        }
        &:checked {
            &:before {
                color: $text-default;
                background-color: $white;
            }
        }
    }
    .checkbox {
        .input-group {
            background-color: initial;
        }
        input {
            &:before {
                border: solid $white 2px;
            }
            + label {
                color: $white;
            }
            &:disabled {
                + label {
                    color: $grey-600;
                }

                &:before {
                    background-color: transparent;
                    border: 2px solid $surface-critical-subdued;
                    color: transparent;
                }
                &:checked {
                    &:before {
                        background-color: $surface-critical-subdued;
                        color: $grey-600;
                    }
                }
            }
        }
    }
    .radio {
        .input-group {
            background-color: initial;
        }
        label {
            color: $white;
        }
        &:disabled {
            + label {
                color: $grey-600;
            }
        }
    }
    .toggle {
        .slider {
            background-color: $grey-600;
            &:before {
                background-color: $white;
            }
        }
        .text {
            color: $white;
        }
        input {
            &:disabled {
                ~ .text {
                    color: $grey-600;
                }
            }
        }
    }
    .select {
        label {
            color: $white;
        }
    }
    .tab-container {
        .nav-item {
            color: $white;
        }
    }
}
