&.documentation {
    display: flex;
    gap: 50px;

    .fileexchange {
        width: 100%;

        .component.file {
            margin-top: 30px;
            width: 100%;
        }
    }
}