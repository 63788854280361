
$fa: "Font Awesome 6 Pro";
$fa-font-path: "/webfonts";

/*GEOMETRY*/
$concretebar: 60px;
$hnav: 80px;
$jumbotronheight: 800px;
$bar-width: 33px;
$bar-height: 1.5px;
$bar-spacing: 10px;


// Forms
$input-height: 40px;

//Brands
$text-default:#1E4072;
$text-default-subdued:#6e92c8;
$surface-highlight:#E0E2F4;
$critical :#9c1b22;
$critical-subdued :#F9DBDB;

//Secondary
$surface-critical:#3E4263;
$surface-critical-subdued: #294d82;
$text-critical:#E74B4B;
$text-safe:#54C280;

//Neutral
$grey-900:#131528;
$grey-800:#393B4E;
$grey-700:#7A7B86;
$grey-600:#AAABB6;
$grey-500:#F4F5F9;
$grey-400: #f5f5f5;

//Hover
$hovered-critical:#DE2B34;
$hovered-critical-subdued:#D3D8FF;

// Colors
$azure: #5c9fd6;
$white: #fff;
$red: #b92127;
$black: #000;
$green: #22b573;
$greenhover: #69edae;
$orange:#ff9500;
$orange-500:#ffc87c;
$blue: #002b58;
$blue-500: rgba(0, 54, 111, 0.7);
$transparent: transparent;
$facebook: #3b5998;
$linkedin: #007bb5;
$whatsapp: #34af23;

/*UI-KIT-COLORS*/
//$text-default: #211745;
$text-dimmed: #5b5864;
$text-disabled: #8c9196;
$text-subdued: #8898ad;
$text-hightlight: #0098ab;
$decorative-one: #ffc96b;
$surface: #ffffff;
$surface-hovered: #e3e6e8;
$surface-subdued: #f1f2f3;
$surface-neutral: #e4e5e7;
//$surface-highlight: #05a2ba;
$surface-highlight-subdued: #dafaff;
//$surface-critical-subdued: #fff4f4;
$surface-decorative: #92e6b5;
$surface-disabled: #f6f6f7;
$surface-selected: #f2f7fe;
$surface-inverse: #211745;
$interactive-default: #2c6ecb;
$background-default: #f6f6f7;
$divider: #e1e3e5;
// $primary: #f22233;
//$critical: #d82c0d;
//$critical-subdued: #fff4f4;
$border-default: $text-default;
$border-subdued: #c9cccf;
$border-hovered: #999ea4;
$border-disabled: #d2d5d8;
$icon-default: $text-default;
$icon-disabled: #babec3;
$focused: #458fff;

@import "functions";