&.list {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);

    .filter,
    .alert {
        grid-column: 1 / -1;
        .face-ghost {
            float: right;
        }
    }

    @import "component.list.articles";
    @import "component.list.tableview";
}

@media only screen and (max-width: 991px) {
    &.list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 767px) {
    .filter,
    .alert {
        .component.button.size-small {
            padding: 0 7px;
            line-height: 30px;
        }
    }

    &.list {
        grid-template-columns: repeat(1, 1fr);
    }
}
