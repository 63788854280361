&.offer {
    transition: transform 0.3s ease-in-out;
    padding: 15px;

    .card-img {
        position: relative;
        height: 225px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &.placeholder {
            background-size: 40%;
        }
        
        .fa-heart {
            position: absolute;
            color: $critical;
            background-color: transparent;
        }
        p {
            position: absolute;
            top: 0px;
            left: 0px;
            color: #000;
            background-color: $surface-highlight;
            padding: 3px 8px;
            border-radius: 5px;
        }
    }
    .card-content{
        text-align: center;
    }

    .card-pre-title {
        margin-top: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 13px;
        color: $grey-700;
    }
    .card-title{
        margin:0;
        padding: 5px;
    }
    .card-text{
        line-height: 20px;
    }

    .button{
        margin-bottom:30px;
    }


    .ribbon{
        margin:10.5px 8.5px;
        padding:8px 10px;
        background-color: $critical;
        display: inline-flex;
        border-radius: 5px;
        color: $white;
        font-size: 12px;
        font-weight: 600;
    }
    
    &:hover {
        transform: scale(1.0162);
    }

    @import "component.tile.offer.standard";
    @import "component.tile.offer.requested";
    @import "component.tile.offer.dashboard";
}
