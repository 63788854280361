footer {
    background-color: $text-default;
    color: $white;
    font-weight: 400;
    .w-100 {
        border-top: 1px solid $grey-700;
    }

    li,
    span,
    a {
        font-weight: 400;
        font-size: 13px;
        line-height: 36px;
        color: $grey-600;
        &.title {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: $white;
        }
    }
    a {
        &:hover {
            color: $white;
        }
    }

    .logo {
        padding: 30px 0;
        font-family: "Oswald";
        font-weight: 700;
        font-size: 24px;
    }
    .main {
        padding: 25px 0px;
        ul {
            li {
                font-weight: 400;
                font-size: 13px;
                line-height: 36px;
                letter-spacing: -0.02em;
                color: grey-600;
                &.title {
                    color: inherit;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 35px;
                }
                &.address {
                    line-height: 15px;
                }
                &.company {
                    font-weight: 500;
                    color: $white;
                }
            }
        }
    }
    .credits {
        a {
        }

        .bluedog {
            text-align: end;
            a {
                font-weight: 700;
                color: $white;
            }
        }
        .row {
            height: 60px;
        }
    }
    .bluedog {
        background-color: $text-default;
    }
}
.auth {
    footer {
        a {
            &:hover {
                color: $text-default;
            }
        }
    }
}
