&.breadcrumbs {
    font-size: 13px;
    font-weight: 400;
    color: $grey-600;
    text-transform: uppercase;
    border-radius: 5px;
    text-align: left;
    ol {
        margin-bottom: 0px;
        padding: 0px;
    }

    a {
        font-size: 13px;
        font-weight: 400;
        color: $grey-600;

        &:hover {
            color: $text-default;
            border-bottom: 2px solid $critical;
        }
        &:active {
            color: $text-default;
            font-weight: 700;
        }
    }

    li {
        display: inline-block;
        &:not(:last-child)::after {
            content: "/";
        }
        &:last-child {
            font-weight: 700;
            color: $text-default;
        }
    }
}
