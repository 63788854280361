h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
}

h2 {
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    margin-top: 20px;
}

h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-top: 15px;
}

h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-top: 10px;
}

p {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 30px;
}

p,
h4,
h3,
h2,
h1 {
    color: $text-default;

    &.critical {
        color: $critical
    }

    &.jumbo {
        color: $white
    }
}

.contrast {

    p,
    h4,
    h3,
    h2,
    h1 {
        color: $white;
    }
}



@media only screen and (max-width : 768px) {
    h1 {
        font-size: 42px;
        line-height: 46px;
    }
    
    h2 {
        font-size: 30px;
        line-height: 36px;
    }
    
    h3 {
        font-size: 26px;
        line-height: 32px;
    }
    
    h4 {
        font-size: 22px;
        line-height: 28px;
    }
    
    p {
        font-size: 16px;
        line-height: 26px;
    }
}