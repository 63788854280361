.tab-container {
    .nav-item {
        color: $grey-600;
        text-transform: uppercase;
        size: 13px;
        line-height: 18px;
        font-weight: 400;
        a {
            color: $grey-600;
        }
    }
    .active {
        border: none;
        background-color: transparent;
    }
    .nav-tabs {
        .nav-link {
            &:hover {
                border-color: transparent;
                color: $black;
            }
            &.active{
                background-color: transparent;
                font-weight: 800;
                border-bottom: 2px $critical solid;
            }
        }
    }
}
