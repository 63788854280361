&.requested {
    padding: 0;
    display: flex;
    flex-direction: row;
    border: none;
    background-color: transparent;

    &:hover {
        transform: none;
    }

    .card-img {
        height: 350px;
        flex: 1 0 50%;
        background-size: 100%;
    }

    .card-content {
        flex: 1 0 50%;
        padding: 25px 70px;
    }

    ul.brokerageinfo {
        padding: 0px;
        list-style-type: none;
        span {
            display: block;

            &.k {
                font-weight: 300;
                font-size: 14px;
            }

            &.v {
                font-weight: 700;
                font-size: 22px;
            }
        }

        ul.services {
            display: flex;
            list-style-type: none;
            li {
                flex: 1 1 0px;

                span.k {
                    margin-top: 0;
                }
            }
        }
    }
    @media only screen and (max-width: 991px) {
        flex-direction: column;
    }
}
