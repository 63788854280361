&.dashboard {
    transition: transform 0.3s ease-in-out;
    border: 1px solid #ebebeb;
    padding: 25px;

    .card-img {
        position: relative;
        height: 100px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .card-content {
        text-align: center;
    }

    .card-pre-title {
        margin-top: 20px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
        color: $grey-700;
    }

    .card-title {
        margin: 0;

        .status {
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            padding: 0 10px;
            border: 1px solid #ebebeb;
            background-color: #999;
            color:white;
            border-radius: 12px;
            letter-spacing: normal;
        }
    }

    .card-text {
        line-height: 20px;
        margin:20px 0;
    }

    .price {
        display: block;
        color: black;
        margin: 5px 0;
        span {
            display: block;
            &.label {
                font-weight: 100;
                font-size: 14px;
            }
            &.value {
                i {
                    font-size: 18px;
                    margin-right: 10px;
                    font-weight: 100;
                }
                font-weight: 700;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }

    .button {
        margin: 20px 0;
    }

    &:hover {
        transform: scale(1.0162);
        .price {
            color: black;
        }
    }
}