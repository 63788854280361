&.accordion {
    gap: 20px;
    grid-template-columns: 1fr;

    .faq {
        padding: 0px;

        button{
            padding-right: 40px;
        }

        h2 {
            margin-top: 0px;
        }
        .question {
            width: 100%;
            text-align: left;
            position: relative;
            color: $text-default;
            &.collapsed {
                &:after {
                    content: "\f078";
                    color: $text-default;
                }
            }
            &:after {
                content: "\f077";
                font-family: $fa;
                font-weight: 400;
                color: $critical;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:focus {
                box-shadow: none;
                outline: none;
                text-decoration: none;
                color: $text-default;
            }
            &:hover {
                text-decoration: none;
                color: $text-default;
            }
        }

        .answer {
            @include nomaxlines();
        }
    }
}
