&.tableview {
    grid-template-columns: 1fr;
    
    .tableopener {
        margin-top: 32px;

        .tabletitle {
            font-size: 21px;
            font-weight: 700;
            line-height: 38px;
        }

        .tableactions {
            .button {
                float: right;
                margin-left: 10px;

                &.tabbed {
                    border-color: $text-default;
                }
            }
        }

        .tablefilters {
            border: 2px solid $text-default;
            background-color: $text-default-subdued;
            border-radius: 8px;
            margin-top: 10px;
            display: none;

            &.form-element {
                .dataTables_filter {
                    label {
                        position: relative;
                        left: 0;

                        &:after {
                            display: none;
                        }
                    }

                    input[type="search"] {
                        width: auto;
                        margin-left: 5px;
                        padding-top: 10px;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    .tabledlist {
        width: 100% !important;
        max-width: 100% !important;
        margin-top: 30px;
        border-collapse: separate;
        border-spacing: 0 4px;
        font-size: 13px;

        td,
        th {
            padding: 0 10px;

            &:first-child {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            &:last-child {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }

        thead {
            .columns {
                th {
                    cursor: pointer;
                    background-color: $gray-700;
                    line-height: 40px;
                    color: $white;
                    position: relative;
                    white-space: nowrap;

                    &.sorting {
                        &:after {
                            content: '';
                            font-family: $fa;
                            font-weight: 100;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 10px;
                        }

                        &.sorting_asc:after {
                            content: '\f176';
                        }

                        &.sorting_desc:after {
                            content: '\f175';
                        }
                    }
                }
            }

            .selectedtools {
                td {
                    height: 42px;
                    line-height: 42px;
                    background: $gray-100;

                    .rowcommands {
                        display: none;
                        width: auto;
                        margin: 0;
                        padding: 0;
                        float: left;

                        border-spacing: 5px 10px;

                        &.visible {
                            display: block;

                            &.oneselected {
                                .onlyone {
                                    display: inline-block;
                                }
                            }
                        }

                        .button {
                            display: inline-block;
                            float: left;
                            margin: 7px 5px 0 0;
                            position: relative;
                            padding: 0 10px;

                            &.onlyone {
                                display: none;
                            }

                            &.active {
                                background-color: $text-default-subdued;
                                border-color: $text-default-subdued;
                                padding-top: 3px;
                                margin-top: 4px;
                                padding-bottom: 7px;
                                border-bottom-right-radius: 0;
                                border-bottom-left-radius: 0;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    background-color: $text-default-subdued;
                                    height: 5px;
                                    bottom: -6px;
                                    left: -2px;
                                    width: calc(100% + 4px);
                                }

                                &:hover {
                                    color: $black;
                                }
                            }
                        }

                    }

                    div.rowselectedcount {
                        float: right;
                        width: 200px;
                        text-align: right;
                        margin: 0;
                        font-size: 12px;
                        color: $gray-200;

                        span {
                            font-weight: 900;
                            padding-right: 5px;
                            display: inline;
                        }
                    }
                }
            }

            .actionwrapper {
                td {
                    padding: 0;
                    height: auto;
                }

                .rowaction {
                    display: none;
                    background-color: $text-default-subdued;
                    border-radius: 6px;
                    padding: 15px 30px;
                }
            }
        }

        .rowselectorall,
        .rowselector,
        .rowdisabled {
            display: block;
            width: 20px;
            float: left;
            cursor: pointer;
            line-height: inherit;
            font-weight: 300;
        }

        tr {
            td {
                height: 60px;

                i.fa,
                i.fas,
                i.fa-solid,
                i.far,
                i.fa-regular,
                i.fal,
                i.fa-light,
                i.fat,
                i.fa-thin,
                i.fad,
                i.fa-duotone,
                i.fab,
                i.fa-brands {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: inherit;

                    &.yellow {
                        color: $yellow;
                    }

                    &.green {
                        color: $green;
                    }

                    &.red {
                        color: $red;
                    }

                    &.gray {
                        color: $gray-400;
                    }
                }

                &.sorting_1 {
                    font-weight: 700;
                }

                select {
                    padding: 5px 10px;
                    line-height: 40px;
                    height: 40px;
                    font-size: 14px;
                    border: 1px solid #e8eaed;
                    border-radius: 5px;
                    background: #fff;
                    box-shadow: 0 1px 3px -2px #9098a9;

                    &:focus {
                        outline: none;
                        border-color: #07f;
                        box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
                    }
                }

                &.loading {
                    &::before {
                        content: ' ';
                        display: block;
                        width: 20px;
                        height: 20px;
                        margin: -1px auto;
                        border-radius: 50%;
                        border: 2px solid $text-default;
                        border-color: $text-default $text-default transparent transparent;
                        animation: lds-dual-ring 1.6s linear infinite;
                    }

                    @keyframes lds-dual-ring {
                        0% {
                            transform: rotate(0deg);
                        }

                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }

                .detail {
                    line-height: 20px;
                    font-size: 13px;
                }

                span {
                    display: block;

                    &.bignumber {
                        font-size: 32px;
                        font-weight: 900;
                        text-align: left;
                        letter-spacing: -1px;

                        i {
                            padding-left: 5px;
                            color: $gray-500;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 26px;
                        }
                    }

                    &.status {
                        line-height: 60px;
                        padding-left: 10px;
                        position: relative;
                        white-space: nowrap;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 5px;
                            height: 40px;
                            left: -4px;
                            top: 10px;
                            border-radius: 5px;
                        }

                        &.rejected::before {
                            background-color: $red;
                        }

                        &.complete::before {
                            background-color: $green;
                        }

                        &.validated::before {
                            background-color: $cyan;
                        }

                        &.partial::before {
                            background-color: $green;
                        }

                        &.new::before {
                            background-color: $yellow;
                        }
                    }
                }

                ul.keynums {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    display: flex;

                    .history-orderlist-year {
                        display: flex;
                        margin-right: 5px;
                    }

                    span {
                        display: block;
                        margin-right: 5px;
                        padding: 0 5px;
                        border-radius: 4px;
                        line-height: 24px;
                        width: 50px;
                        text-align: right;

                        &.year {
                            color: $gray-700;
                            font-weight: 700;
                        }

                        &.iconic {
                            position: relative;
                            padding-left: 20px;

                            &:after {
                                content: "";
                                font-family: $fa;
                                font-weight: 100;
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                height: 24px;
                                width: 20px;
                                color: $white;
                                text-align: center;
                                line-height: 24px;
                            }

                            &.closed {
                                background-color: $gray-500;
                                border: 1px solid $green;

                                &:after {
                                    color: $green;
                                    content: '\f46c';
                                }
                            }

                            &.wip {
                                background-color: $orange-500;
                                border: 1px solid $orange;

                                &:after {
                                    color: $orange;
                                    content: '\f013';
                                }
                            }

                            &.new {
                                background-color: $gray-100;
                                border: 1px solid $gray-700;

                                &:after {
                                    color: $gray-700;
                                    content: '\f133';
                                }
                            }

                            &.total {
                                background-color: $gray-100;
                                border: 1px solid $gray-700;

                                &:after {
                                    color: $gray-700;
                                    content: '\e417';
                                }
                            }

                            &.tickets {
                                background-color: $gray-100;
                                border: 1px solid $gray-700;

                                &:after {
                                    color: $gray-700;
                                    content: '\f013';
                                }
                            }

                            &.tickets_closed {
                                background-color: $gray-100;
                                border: 1px solid $gray-700;

                                &:after {
                                    color: $gray-700;
                                    content: '\f058';
                                }
                            }
                        }
                    }
                }
            }

            &.odd {
                td {
                    background-color: $gray-100;
                }
            }

            &.even {
                td {
                    background-color: $white;
                }
            }

            &.clickable {
                cursor: pointer;

                &:hover {
                    td {
                        background-color: $text-default-subdued;
                    }
                }
            }

            &.disabled {
                cursor: no-drop;
                opacity: 0.2;
            }

            &.deleted {
                height: 5px;

                td {
                    height: 5px;
                    line-height: 0;
                    font-size: 0;
                    background-color: $gray-700;

                    input,
                    select,
                    div {
                        display: none;
                    }
                }
            }

            &.child {
                table {
                    width: 100%;
                    background: $text-default-subdued;
                    border-radius: 5px;

                    th {
                        padding: 10px;
                    }
                }
            }
        }
    }

    .tablecloser {
        border-radius: 6px;
        border: 1px solid $gray-100;
        margin: 0 15px;
        width: 100%;
        flex: unset;
        line-height: 40px;
        height: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        >div {
            display: inline-block;

            label {
                margin-bottom: 0;
            }

            font-size: 12px;
            font-weight: 300;
            line-height: 40px;
            text-align: left;

            color:$gray-500 select {
                background-color: $white;
            }

            .paginate_button {
                cursor: pointer;

                &:hover {
                    color: $text-default;
                }
            }

            span {
                a.paginate_button {
                    padding: 5px;
                    border: 1px solid $gray-100;
                    margin: 0 2px;
                }
            }

            &:nth-child(2) {
                text-align: center;
            }

            &:last-child {
                >label {
                    float: right;
                }
            }
        }
    }

    .dataTables_info,
    .dataTables_paginate,
    .dataTables_length {
        margin-top: 5px;
        display: inline;
        font-size: 12px;
        color: $gray-700;
    }

    .dataTables_info {
        padding-right: 30px;
    }

    .dataTables_paginate {
        float: right;

        a {
            margin: 0 5px;

            &.disabled {
                &:hover {
                    color: inherit;
                }
            }

            &.paginate_button {
                padding: 2px 5px;
                border: 0.5px solid $gray-400;
                border-radius: 4px;

                &.current {
                    border-color: $text-default;
                }

                &:hover {
                    background-color: $gray-800;
                    color: $white;
                    cursor: pointer;
                }
            }
        }
    }
}