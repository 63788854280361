main {
    padding: 80px 0;
    background-color: $white;
    min-height: 100vh;

    section {
        &:first-child {
            padding-top: 80px;
        }

        padding-top: 40px;
        padding-bottom: 40px;
    }

    .linkurl {
        position: absolute;
        top: 10px;
        right: 30px;
        text-decoration: underline $critical 3px;
        font-weight: 700;
    }

    .header-wishlist {
        text-align: center;
        padding-top: 60px;
        .page-pretitle {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
        }
        .page-title {
            font-size: 48px;
            font-weight: 700;
            line-height: 65px;
            letter-spacing: -0.02em;
        }
        .page-description {
            font-size: 18px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
        }
    }
    .wishlist-container {
        padding: 30px 0;
    }
    .contactContainer {
        padding-top: 30px;
        padding-bottom: 30px;
        background: $surface-highlight;
        border-radius: 10px;
        justify-content: space-between;

        .contact-img {
            height: 140px;
            width: 231px;
            border-radius: 0px;
            margin: 10px auto;
            img {
                position: absolute;
                bottom: 0px;
            }
        }
        .pretitle,
        .title {
            font-size: 24px;
            letter-spacing: -0.03em;
            line-height: 31px;
        }
        .pretitle {
            font-weight: 300;
            margin: 0;
        }
        .title {
            font-weight: 800;
            margin-top: 0px;
            margin-bottom: 25px;
        }
        .contact_cta {
            margin: 0;
        }
        @media only screen and (max-width: 767px) {
            .contact-img {
                display: none;
            }
        }
    }
    .reviews {
        background-color: $white;
        i {
            &::before {
                background: $critical;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 50px;
                margin-right: 15px;
                font-size: 30px;
                color: white;
            }
        }
        .quote {
            margin-bottom: 40px;
            p {
                font-size: 16px;
                line-height: 22px;
                margin: 0px;
            }
            .name {
                font-weight: 600;
            }
        }
        p {
            font-size: 21px;
            line-height: 31px;
        }
        .review-container {
            background-color: $grey-500;
            border-radius: 10px;
        }

        // background-color: $white;
        // padding: 20px 100px 0;
        // .row {
        //     flex-direction: row;
        //     justify-content: center;
        //     align-items: center;
        //     &.first {
        //         margin-bottom: 15px;
        //     }
        // }
        // .quote {
        //     display: flex;
        //     i {
        //
        //     }
        // }
        // .name,
        // .role {
        //     width: fit-content;
        // }
        // .review {
        //     margin-top: 30px;
        //     font-family: "Noto Sans";
        //     font-size: 21px;
        //     font-weight: 300;
        //     line-height: 36px;
        //     letter-spacing: -0.02em;
        // }
        @media only screen and (max-width: 767px) {
            .review-container {
                padding: 48px 0;
            }
            .person {
                display: none;
            }
        }
        p {
            margin: 0;
        }
    }

    .magazineContainer {
        margin-top: 25px;
        .row {
            flex-direction: column;
            .contentlist {
                &.magazine {
                    display: flex;
                    gap: 15px;
                    margin-bottom: 20px;
                    margin-top: 30px;
                }
            }
        }
    }
    .faqContainer {
        margin-top: 60px;
        margin-bottom: 90px;
        .faq-list {
            display: flex;
            gap: 30px;
            margin-top: 60px;
        }
        .faq {
            background: #ffffff;
            box-shadow: 10px 10px 80px -20px rgba(68, 55, 90, 0.1);
            border-radius: 6px;
            flex-grow: unset;
            flex-shrink: unset;
            flex-basis: unset;
            padding: 20px;
            &-question {
                font-family: "Readex Pro";
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: $text-default;
                margin-bottom: 15px;
            }
            &-answer {
                font-family: "Noto Sans";
                font-weight: 400;
                font-size: 13px;
                letter-spacing: -0.02em;
                color: $text-default;
            }
        }
    }
    .nlContainer {
        padding: 0;
    }

    ul,
    ol {
        padding-inline-start: 40px;
        font-size: 18px;
        color: $text-default;
        font-weight: 300;
        list-style-type: circle;

        &.flexible {
            display: flex;
            gap: 30px;
            margin: 30px 0;
            list-style-type: none;
            padding: 0;
            li {
                flex: 1 1 0px;
                border: 0.5px solid $grey-700;
                border-radius: 5px;
                padding: 50px 30px;
                strong {
                    display: block;
                    font-size: 22px;
                    margin-bottom: 20px;
                    font-weight: 700;
                }
            }
            @media only screen and (max-width: 767px) {
                flex-direction: column;
            }
        }
    }
    ol {
        list-style-type: decimal;
    }
}
@import "colorinverse";
@import "form.create";
