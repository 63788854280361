.header {
    padding: 15px 0;
    background-color: white;
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 10;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);

    .logo {
        font-family: "Oswald";
        font-weight: 700;
        font-size: 24px;
        display: flex;
        align-items: center;
    }

    // .search {
    //     flex: 0 0 55.333%;
    //     max-width: 55.333%;
    //     display: flex;
    //     align-items: center;

    //     .searchContainer {
    //         width: 320px;
    //         background: #f3f3f3;
    //         border-radius: 5px;
    //         border: none;
    //         font-family: "Open Sans";
    //         font-weight: 400;
    //         font-size: 12px;
    //         line-height: 26px;
    //         position: relative;
    //         display: inline-flex;

    //         .globalsearch {
    //             border: none;
    //             background: transparent;
    //             width: 100%;
    //             padding: 5px 15px;
    //             border-radius: inherit;
    //         }

    //         i {
    //             font-size: 15px;
    //             display: flex;
    //             justify-content: end;
    //             align-self: center;
    //             padding-right: 15px;
    //         }
    //     }
    // }

    .navbar {
        padding: 0;
        line-height: 54px;
        justify-content: space-evenly;
        width: 100%;

        li {
            a {
                display: block;
                line-height: 54px;
                font-size: 18px;
                letter-spacing: -0.5px;
                color: $text-default;

                &:hover {
                    color: $critical;
                }
            }
        }
    }

    .user_menu {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .user {
            background-color: #f3f3f3;
            border-radius: 5px;
            position: relative;
            z-index: 5;
            width: 100%;
            display: flex;

            .usertag {
                background-color: $critical;
                color: white;
                border-radius: 4px;
                height: 30px;
                min-width: 30px;
                display: inline-block;
                padding: 0px 8px;
                line-height: 30px;
                margin-right: 10px;
            }

            a,
            span.username {
                display: inline-block;
                line-height: 30px;
                font-size: 16px;
            }
        }

        .lang {
            .flag {
                width: 30px;
                height: 30px;
                background-position: center;
                background-size: cover;
                border-radius: 5px;
                float: right;
            }
        }

        .user,
        .lang {
            padding: 12px 10px 12px 10px;

            &.listContainer {
                padding-right: 20px;

                &:after {
                    content: "\f150";
                    font-family: $fa;
                    display: inline-block;
                    position: absolute;
                    right: 10px;
                    top: 12.5px;
                    cursor: pointer;
                }
            }
        }

        // .searchbar {
        //     background-color: #f3f3f3;
        //     border-radius: 5px;
        //     padding: 12px 12px 12px 20px;
        //     font-weight: 400;
        //     font-size: 12px;
        //     line-height: 16px;

        //     i {
        //         margin-left: 5px;
        //     }
        // }

        // .icons {
        //     display: flex;
        //     justify-content: flex-end;
        //     align-items: center;
        //     margin-left: 15px;

        //     .notifications {
        //         position: relative;

        //         &:hover {
        //             cursor: pointer;
        //         }

        //         .n_notify {
        //             color: #fff;
        //             background-color: $critical;
        //             padding: 1px;
        //             position: absolute;
        //             top: -4px;
        //             right: 2px;
        //             font-size: 9px;
        //             border-radius: 50%;
        //             height: 15px;
        //             width: 15px;
        //             display: flex;
        //             justify-content: center;
        //             align-content: center;
        //             font-weight: 900;
        //         }

        //         &::before {
        //             content: "\f0f3";
        //         }

        //         .notify_list {
        //             position: absolute;
        //             top: 40px;
        //             width: 240px;
        //             background: #ffffff;
        //             border-radius: 10px;
        //             left: -110px;
        //             transform: translateX(-100px);
        //             z-index: 99999999;

        //             li {
        //                 display: flex;
        //                 align-items: center;

        //                 &:nth-of-type(1) {
        //                     background: #f3f3f3;
        //                     border-radius: 10px 10px 0px 0px;
        //                 }

        //                 &:nth-of-type(2) {
        //                     border-top: 1px solid #dddddd;
        //                     border-bottom: 1px solid #dddddd;
        //                     border-radius: none;
        //                 }

        //                 &:nth-of-type(3) {
        //                     border-radius: 0px 0px 10px 10px;
        //                     border: 1px solid grey;
        //                     border-top: none;
        //                 }

        //                 &::before {
        //                     font-family: $fa;
        //                     width: 50px;
        //                     height: 50px;
        //                     border-radius: 50%;
        //                     background-color: #dddddd;
        //                     display: flex;
        //                     align-items: center;
        //                     justify-content: center;
        //                     margin: 0 5px;
        //                     font-size: 27px;
        //                 }

        //                 &.docs::before {
        //                     content: "\f15c";
        //                 }

        //                 &.info::before {
        //                     content: "\f059";
        //                 }

        //                 &.alert_msg::before {
        //                     content: "\21";
        //                 }

        //                 .notify_container {
        //                     margin-left: 10px;

        //                     span {
        //                         &:nth-of-type(1) {
        //                             font-size: 15px;
        //                             line-height: 21px;
        //                             color: #000000;
        //                             font-weight: 700;
        //                         }

        //                         &:nth-of-type(2) {
        //                             font-size: 13px;
        //                             line-height: 18px;
        //                         }

        //                         &:nth-of-type(3) {
        //                             font-size: 12px;
        //                             line-height: 11px;
        //                             color: #999999;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }

        //     .likes {
        //         &::before {
        //             content: "\f004";
        //         }
        //     }

        //     .hamburger {
        //         cursor: pointer;

        //         &::before {
        //             content: "\f150";
        //         }

        //         &.open {}
        //     }

        //     .user_login {
        //         width: 30px;

        //         i {
        //             margin: 0px 7px;
        //         }
        //     }

        //     .notifications,
        //     .likes,
        //     .hamburger {
        //         &::before {
        //             font-family: $fa;
        //             height: 20px;
        //             width: 20px;
        //             margin: 0 5px;
        //             //display: inline-block;
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //         }
        //     }
        // }

        // &.guest {
        //     justify-content: flex-end;
        // }
    }

    .hidden {
        display: none;
    }

    .hide_menu_list {
        position: absolute;
        top: 65px;
        left: 0;
        z-index: -1;
        background-color: white;
        width: -moz-available;
        padding: 5px 0 10px;
        border-radius: 0 0px 8px 8px;
        box-shadow: 0 5px 8px rgba(0,0,0,0.1);
    
        .menu_list {
            margin: 0;

            li {
                margin: 0;
                padding: 5px 30px;
                display: block;
                width: 100%;

                i {
                    font-size: 14px;
                    margin: 0;
                    margin-right: 10px;
                    color: $critical;

                    &.icon-it {
                        background-image: url(/images/flags/it.svg);
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background-size: 120%;
                        background-position: center;
                    }

                    &.icon-en {
                        background-image: url(/images/flags/en.svg);
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background-size: 150%;
                        background-position: center;
                    }
                }

                &.profile {
                    &::before {
                        margin: 0 15px;
                        font-family: $fa;
                        content: "\f007";
                        color: $critical;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .header {
        .logo,
        .nav,
        .user_menu {
        }

        .nav {
            position: relative;

            cursor: pointer;

            &::before {
                content: "\f0c9";
                font-family: $fa;
                color: $critical;
                font-size: 24px;
                font-weight: 100;
                text-align: center;
                width: 100%;
                line-height: 50px;
            }

            &:hover {
                &::before {
                    color: $text-default;
                }
            }

            .listContainer {
                position: absolute;
                width: 100%;
                height: 50px;
            }
        }

        .user_menu {
            .user {
                background-color: transparent;

                .usertag {
                    &.logged {
                        font-size: 10px;
                    }
                }

                span.username {
                    display: none;
                }

                a {
                    span {
                        display: none;
                    }
                }
            }

            .user,
            .lang {
                cursor: pointer;
                width: 50px;

                &.listContainer {
                    padding-right: 20px;

                    &:after {
                        content: "";
                    }
                }
            }
        }

        .hide_menu_list {
            position: fixed;
            top: 80px;
            margin: 0;
            box-shadow: 0 20px 10px rgba(0, 0, 0, 0.1);
            background-color: $gray-100;
            border-top: 1px solid $gray-300;

            > ul > li {
                position: relative;

                ul{
                    margin-left: 30px;
                }

                ul li {
                    position: relative;
                    padding-left: 25px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-left: 0.5px solid #aaabb6;
                        border-bottom: 0.5px solid #aaabb6;
                        width: 20px;
                        height: 16px;
                    }
                }
                a.shortcut {
                    position: absolute;
                    right: 0;
                    top: 9px;
                }
            }

            .menu_list {
                margin: 0;

                li {
                    margin: 0;
                    padding: 5px 30px;
                    display: block;
                    width: 100%;

                    a {
                        font-size: 18px;
                        color: $text-default;
                        line-height: 36px;
                    }
                }
            }
        }
    }
}
