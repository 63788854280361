&.overview {
    border-radius: 5px;
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0) 51.56%,
            rgba(0, 0, 0, 0.7) 100%
        ),
        url("../../images/jumbo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    .card-title {
        h4{
            color: $white;
            font-size: 28px;
            font-weight: 700;
            line-height: 30px;
            padding-right: 40px;
        }
        p{
            color: $white;
            position: absolute;
            bottom:50px;
            font-weight: 400;
            font-size:18px;
            line-height: 24px;
            padding-right: 40px;
        }
   
    }
    .card-img{
        position: absolute;
        height: 100%;
        background:linear-gradient(180deg, rgba(51, 51, 51, 0.30) 0%, rgba(0, 0, 0, 0.00) 51.56%, rgba(51, 51, 51, 0.30) 100%);
    }
    .card-text {
  
    }
    .cta {
        color: $white;
   
    }
    + .article{
        .card-img{
            height: 240px;
        }
    }
    @media only screen and (max-width : 767px) {
    height:480px;
    }
    
}