&.button {
  position: relative;
  display: inline-block;
  width: fit-content;
  padding: 0px 14px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  background-color: $white;

  // SIZES
  &.size-micro {
    line-height: 22px;
    font-weight: 300;
    font-size: 12px;
    padding: 0 10px;
  }

  &.size-small {
    line-height: 40px;
  }

  &.size-medium {
    padding: 0px 20px;
    line-height: 50px;
    font-size: 18px;
  }

  &.size-large {
    padding: 0px 24px;
    line-height: 60px;
    font-size: 21px;
  }

  // FACES
  &.face-primary {
    border: 1px solid $critical;
    background-color: $critical;
    color: $white;

    &:hover {
      color: $white;
      background-color: $hovered-critical;
      border-color: $hovered-critical;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        color: $white;
        background-color: $critical;
      }

      &.inverse {
        color: $white;
        background-color: $critical;
      }
    }
  }

  &.face-secondary {
    border: 1px solid $surface-highlight;
    background-color: $surface-highlight;
    color: $text-default;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  &.face-outline {
    border: 1px solid $text-default;
    color: $text-default;
    background-color: transparent;

    &.inverse {
      border: 1px solid $white;
      color: $white;
      background-color: $text-default;
    }

    &:hover {
      color: $white;
      background-color: $text-default;

      &.inverse {
        background-color: $white;
        color: $text-default;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;

      &:hover {
        color: $text-default;
        background-color: transparent;

        &.inverse {
          color: $white;
          background-color: $text-default;
        }
      }
    }
  }

  &.face-ghost {
    border: none;
    border-radius: 0%;
    border-bottom: 2px solid $critical;
    background-color: transparent;
    color: $text-default;
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    margin-left: auto;

    &.inverse {
      color: $white;
    }
  }
}