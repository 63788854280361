&.file {
    height: 200px;
    position: relative;
    label {
        height: 100%;
        border-radius: 10px;
        text-align: center;
        background-image: url("/images/default/vehicle/load_doc.svg");
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 140px;

        &.hasimage {
            background-size: cover !important;
            .isolator {
                line-height: 16px;
                padding:0 10px;
                border-radius: 8px;
                background-color: rgba(255,255,255,0.8);
            }
        }
    }
    .input-group {
        position: absolute;
        bottom: 0;
        height: 100%;
        input {
            border-style: dashed;
            border-radius: 10px;
            &[required]:valid {
                border: 2px solid greenyellow;
            }
        }
    }
    input.form-control {
        background: transparent;
        height: 100%;
        &::file-selector-button {
            background-color: $white;
            color: $text-default;
            border-radius: 5px;
            border: 1px $grey-700 solid;
        }
    }
    &.photo {
        // Generic
        label {
            background-image: url("/images/default/vehicle/load_img.svg");
            background-size: 8%;
        }

        //EXTERNAL PHOTO
        &.fr label {
            background-image: url("/images/default/vehicle/file_photo_front_right.webp");
            background-size: 30%;
        }
        &.rs label {
            background-image: url("/images/default/vehicle/file_photo_side_right.webp");
            background-size: 30%;
        }
        &.rr label {
            background-image: url("/images/default/vehicle/file_photo_back_right.webp");
            background-size: 30%;
        }
        &.fv label {
            background-image: url("/images/default/vehicle/file_photo_front.webp");
            background-size: 30%;
        }
        &.rv label {
            background-image: url("/images/default/vehicle/file_photo_back.webp");
            background-size: 30%;
        }
        &.fl label {
            background-image: url("/images/default/vehicle/file_photo_front_left.webp");
            background-size: 30%;
        }
        &.ls label {
            background-image: url("/images/default/vehicle/file_photo_side_left.webp");
            background-size: 30%;
        }
        &.rl label {
            background-image: url("/images/default/vehicle/file_photo_back_right.webp");
            background-size: 30%;
        }
        //INTERNAL PHOTO
        &.sw label {
            background-image: url("/images/default/vehicle/file_photo_steering_wheel.webp");
            background-size: 30%;
        }
        &.d label {
            background-image: url("/images/default/vehicle/file_photo_dashboard.webp");
            background-size: 30%;
        }
        &.c label {
            background-image: url("/images/default/vehicle/file_photo_cockpit.webp");
            background-size: 30%;
        }
        &.gl label {
            background-image: url("/images/default/vehicle/file_photo_gear_tunnel.webp");
            background-size: 30%;
        }
        &.ds label {
            background-image: url("/images/default/vehicle/file_photo_driver_seat.webp");
            background-size: 30%;
        }
        &.ps label {
            position: relative;
            overflow: hidden; /* Assicura che il pseudo-element non sbordi */
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url("/images/default/vehicle/file_photo_passenger_seat.webp");
                background-size: 30%;
                background-repeat: no-repeat;
                background-position: center;
                transform: scaleX(-1); 
                z-index: 0; 
            }
        }
                &.rss label {
            background-image: url("/images/default/vehicle/file_photo_rear_seats.webp");
            background-size: 30%;
        }
        &.t label {
            background-image: url("/images/default/vehicle/file_photo_trunk.webp");
            background-size: 30%;
        }
    }
    .existing_document_link {
        position: absolute;
        background-color: $green;
        color: $white;
        font-size: 14px;
        font-weight: 300;
        top:0;
        right: 0;
        z-index: 2;
        padding: 0 20px;
        line-height: 50px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 15px;
        &:hover {
            background-color: $gray-200;
            color:$critical;
        }
    }
}
