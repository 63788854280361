&.sidebar {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 78px;
    left: 0px;
    background-color: $grey-500;
    padding: 20px;
    > ul {
        > li {
            a.nav-link {
                display: block;
                width: 100%;
                color: $grey-600;
                i {
                    width: 25px;
                }
            }
            a.shortcut {
                position: absolute;
                right: 0;
                top: 9px;
            }
            &::marker {
                color: transparent;
            }

            span.count {
                font-size: 10px;
                display: inline-block;
                text-align: center;
                background-color: green;
                color:white;
                padding:0 5px;
                border-radius: 10px;
                margin-left: 5px;
                &.count-0 {
                    background-color: gray;
                }
            }

            ul {
                margin-left: 46px;
                li {
                    padding-left: 25px;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-left: 0.5px solid $grey-600;
                        border-bottom: 0.5px solid $grey-600;
                        width: 20px;
                        height: 16px;
                    }
                    a {
                        font-size: 12px;
                        font-weight: 100;
                        padding: 5px 0;
                    }
                }
            }
        }
    }
    ul {
        li {
            position: relative;
            a:hover {
                color: $text-default;
            }
            &.active {
                > a {
                    color: $text-default;
                    cursor: default;
                }
            }
        }
    }
    
    @media only screen and (max-width: 767px) {
        display: none;
    }
}
