@import "../variables";

.swiper {
	--swiper-navigation-size: 30px;
	--swiper-theme-color: #000;
	--swiper-pagination-bullet-inactive-color: #f2f2f2;
	--swiper-pagination-bullet-inactive-opacity: 1;

	.swiper-pagination-bullet {
		border: 1px solid $black;
	}
	.swiper-button-prev,
	.swiper-button-next {
		width: 48px;
		height: 48px;
		background-color: $white;
		border-radius: 50px;
		color: $text-default;
		box-shadow: 0 1px rgba(0, 0, 0, 0.05);
		&:after {
			font-size: 14px;
			font-weight: 700;
		}
	}
}

.swiperconfig{
	display: none;
}

.component.contentlist {
	.swiper {
		padding: 40px 2px 2px;
		margin-top: -40px;

		margin-right: 10px;
		margin-left: 10px;
		width: calc(100% - 24px);
		max-width: calc(100% - 24px);

		.swiper-controls {
			position: absolute;
			top: 0;
			width: 100%;
			height: 40px;

			.swiper-pagination {
				display: inline-block;
				right: 75px;
				left: auto;
				bottom: auto;
				width: auto;
				font-size: 14px;
				line-height: 40px;
				&.swiper-pagination-by-name {
					display: flex;
					top: 0px;
					right: 45px;
					.swiper-pagination-bullet {
						display: flex;
						justify-content: center;
						align-items: center;
						border: none;
						padding: 10px 0;
						width: 170px;
						border-radius: 10px;
					}
					.swiper-pagination-bullet-active {
						color: white;
						background-color: #211745;
						padding: 15px 0;
					}
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				width: 30px;
				height: 30px;
				background-color: transparent;
				border-radius: 0px;
				box-shadow: none;
				left: auto;
				line-height: 40px;
				//top:22px;
			}

			.swiper-button-prev {
				right: 40px;
			}

			.swiper-button-next {
				right: 0px;
			}

			.swiper-button-lock {
				display: flex;
			}
			.swiper-button-disabled{
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.component.contentlist {
		.swiper {
			padding-top: 0px;
			margin-top: 0px;
			.swiper-controls {
				display: none;
			}
		}
	}
}
