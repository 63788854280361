&.vehicle {
    transition: transform 0.3s ease-in-out;
    border: 1px solid #ebebeb;
    padding: 25px;

    .card-img {
        position: relative;
        height: 225px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .card-content {
        text-align: center;
    }

    .card-pre-title {
        margin-top: 20px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
        color: $grey-700;
    }

    .card-title {
        margin: 0;

        .setup {
            display: block;
            font-size: 18px;
            font-weight: 300;
        }
    }

    .card-text {
        line-height: 20px;
        margin:20px 0;
    }

    .button {
        margin-bottom: 30px;
    }

    &:hover {
        transform: scale(1.0162);
    }
}