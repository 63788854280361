&.checkbox {
    display: inline-flex;
    align-items: baseline;

    .input-group {
        &:focus-within {
            border: none;
            box-shadow: none;
        }
        :last-child {
            height: auto;
        }
    }

    input {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        padding: 0px 15px;
        width: auto;
        background-color: transparent;
        position: relative;
        &:focus-within {
            border: none;
            box-shadow: none;
        }

        &:before {
            position: absolute;
            content: "\f00c";
            font: var(--fa-font-solid);
            font-family: $fa;
            font-style: normal;
            color: transparent;
            border: solid $text-default 2px;
            border-radius: 2px;
            padding: 2px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:focus {
            &:before {
                outline: none;
                box-shadow: 0px 0px 0px 3px rgba(224, 226, 244, 0.5);
            }
        }

        &:checked {
            &:before {
                color: $white;
                background-color: $text-default;
            }
        }
        &:disabled {
            cursor: not-allowed;
            + label {
                color: $grey-600;
                cursor: not-allowed;
            }

            &:before {
                background-color: transparent;
                border: 2px solid $grey-600;
                color: transparent;
                cursor: not-allowed;
            }
            &:checked {
                &:before {
                    background-color: $grey-600;
                    color: $white;
                }
            }
        }

        + label {
            cursor: pointer;
            padding-left: 10px;
            width: auto;
            color: #1e4072;
            margin-bottom: 0px;
            text-transform: initial;
            line-height: initial;
        }
    }
}
