body {
    font-family: "Roboto";

    .zoomedimage {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #222;

        cursor: zoom-out;

        .detail {
            padding: 0 !important;
            width: 22%;
            position: fixed;
            bottom: 30px;
            right: 30px;

            h1,
            h4 {
                color: $white;
                text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
            }

            .breadcrumbs {
                display: none;
            }

            ul {
                li {
                    display: none;

                    &.highlight {
                        display: block;
                    }
                }
            }
        }

        @media only screen and (max-width : 767px) {

            background-position: 50%;

            .detail {
                width: 60%;
            }
        }
    }

    .payment_rationale {
        padding: 30px 0px;

        p {
            text-transform: none;
            color: $gray-600;
            font-size: 16px;
        }
    }

    .select2-container--default {
        .select2-selection--single {
            color: $text-default;
            height: $input-height;

            font-size: 16px;
            font-weight: 300;
            line-height: $input-height;
            border: 0.5px solid $gray-800;

            .select2-selection__rendered {
                padding: 0 20px;
                line-height: $input-height;
            }

            .select2-selection__arrow {
                height: $input-height;
                width: 13px;

                b {
                    border: 1.5px solid $gray-800;
                    width: 5px;
                    height: 5px;
                    transform: rotate(45deg);
                    border-left: none;
                    border-top: none;
                    top: 45%;
                }
            }
        }

        .select2-search__field {
            font-size: 16px;
            font-weight: 300;
            line-height: $input-height;
            border: 0.5px solid $gray-800;
            border-radius: 5px;
            padding: 0 20px;
        }

        .select2-results__options {
            .select2-results__option--selectable {
                font-size: 16px;
                font-weight: 300;
                line-height: math.div($input-height, 2);
                padding-left: 20px;
            }
        }
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    color: #000;

    &:hover {
        color: $critical;
        text-decoration: none;
    }
}