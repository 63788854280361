@use "sass:math";

// Variables
@import 'variables';

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

// Font Awesome 6.4.0 PRO
@import './fontawesome_6_4_0_pro/fontawesome.scss';
@import "./fontawesome_6_4_0_pro/light.scss";
@import "./fontawesome_6_4_0_pro/regular.scss";
@import "./fontawesome_6_4_0_pro/solid.scss";

//Swiper
@import 'swiper/css/bundle';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Parts
@import 'components/component';
@import 'layout/auth';
@import 'layout/footer';
@import 'layout/header';
@import 'layout/pages';
@import 'snippets/swiper';
@import 'typography/typography';
@import 'ui-kit/ui-kit';
@import 'main';
