.auth {
    main {
        margin-left: 250px;
    }
    footer {
        background-color: $grey-500;
        div,
        a {
            color: $grey-600;
        }
    }
    .container {
        max-width: 100%;
        padding: 0px 60px;
    }
    @media only screen and (max-width: 767px) {
        main {
            margin-left: 0px;
        }
    }
}
