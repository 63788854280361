&.tile {
    align-items: center;
    border: none;
    border-radius: 5px;
    background-color: $surface;
    box-shadow: 10px 10px 70px -20px rgba(60, 55, 90, 0.1);
    width: 100%;
    a {
        width: 100%;
    }
    .card-title {
        font-weight: 800;
        letter-spacing: -0.03em;
        color: $text-default;
    }
    .card-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $text-subdued;
        margin-bottom: 40px;
    }
    .card-img {
        background-size: contain;
    }
    .ribbon {
        margin: 10.5px 8.5px;
        padding: 8px 10px;
        background-color: $critical;
        display: inline-flex;
        border-radius: 5px;
        color: $white;
    }

    &.price {
        width: 360px;
        height: 460px;
        padding: 8px;
        gap: 10px;
        hr {
            height: 16px;
            background-color: #000;
            width: 100%;
            margin: 0;
            border-radius: 16px;
        }
        .stars {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
        }
        .card-pre-title {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            padding: 0 24px;
            .type {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .card-title {
            font-size: 36px;
            line-height: 49px;
        }
    }

    @import "component.tile.article";
    @import "component.tile.offer";
    @import "component.tile.request";
    @import "component.tile.vehicle";
    @import "component.tile.contract";
}
