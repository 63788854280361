
&.overview {
    grid-template-columns: 6fr 3fr;
    margin-bottom: 30px;

    @media only screen and (max-width : 991px) {
        grid-template-columns: repeat(2, 1fr);    
    }
      
    @media only screen and (max-width : 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
