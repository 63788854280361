&.form-element {
    margin: 8px 0px;
    display: inline-block;

    &.double {
        grid-column: span 3 / span 3;
    }

    &.triple {
        grid-column: span 2 / span 2;
    }
    
    &.pricecard {
        display: flex;
        gap:30px;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: block;
            background-color: $gray-100;
            border-radius: 5px;
            padding: 50px;
            text-align: center;
            font-size: 16px;
            width: 100%;
            strong {
                display: block;
                margin-top: 20px;
                font-size: 50px;
                color:$text-default;
            }
            i.fa-light {
                font-size: 40px;
            }
            .small {
                margin-top: 15px;
                font-size: 16px;
                line-height: 24px;
                padding: 0 20%;
                color: $grey-600;
            }
        }
    }

    &.hidden {
        margin:0;
        display: none;
    }
    
    br {
        display: none;
    }

    label {
        font-weight: 300;
        width: 100%;
        font-size: 14px;
        color: $grey-800;
    }

    &.required {
        label::after {
            content: "*";
            color: $critical;
            margin-left: 5px;
        }
    }

    i {
        position: relative;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .reasonwhy {
        display: none;
    }

    .input-group {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        &.textarea {
            height: 100px;

            textarea {
                height: 100%;
                width: 100%;
            }
        }

        textarea,
        select,
        input:not([type="checkbox"], [type="radio"], .button) {
            color: $text-default;
            height: $input-height;

            font-size: 16px;
            font-weight: 300;
            padding: 0 20px;
            line-height: $input-height;
            border: 0.5px solid $gray-800;

            &::placeholder {
                color: $grey-600;
            }

            &:focus {
                outline: none;
            }
        }

        .input-group-prepend,
        .input-group-append {
            background-color: $gray-800;

            .input-group-text {
                color: $gray-100;
                border: none;
                background-color: transparent;
                padding: 0 10px;
                line-height: $input-height;

                i {
                    width: 20px;
                }

                .fa-magnifying-glass {
                    color: $text-default;
                }

                .fa-eye-slash,
                .fa-eye {
                    cursor: pointer;
                }
            }
        }

        > :last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            height: 40px;
        }

        > :first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            height: 40px;
        }

        &:focus-within {
            box-shadow: 3px 3px 40px -4px rgba(41, 45, 82, 0.05),
                0px 0px 0px 4px rgba(224, 226, 244, 0.5);

            input {
                box-shadow: none;
            }
        }

        &.error {
            border: 1.2px solid $text-critical;
            box-shadow: 0px 0px 0px 4px rgba(231, 75, 75, 0.4);

            :focus {
                border: none;
            }

            .input-group-text {
                color: $text-critical;
            }

            &:focus-within {
                border: none;
            }
        }

        input {
            width: auto;
        }

        input[type="color"] {
            padding: 4px;
            border-radius:5px 0  0 5px;
        }

        input[type="date"] {
            &::-webkit-calendar-picker-indicator {
                color: $text-default;
                font-size: 20px;
            }
        }

        input[type="hidden"] {
            margin: 0px;
        }

        .textarea {
            height: 140px;

            textarea {
                height: 100%;
            }
        }
    }
    @media only screen and (max-width : 767px) {
        &.double, 
        &.triple{
            grid-column: 1 / -1;
        }
    
    }

    @import "component.formelement.checkbox";
    @import "component.formelement.radio";
    @import "component.formelement.select";
    @import "component.formelement.textarea";
    @import "component.formelement.toggle";
    @import "component.formelement.file";
}
