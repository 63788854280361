&.jumbotron {
    padding: 0px;

    .jumbotronContainer {
        background: no-repeat center center;
        background-size:cover;;
        background-size: cover;
        height: 640px;
        border-radius: 0px;
        display: flex;
        align-items: center;

        .button {
            margin-top: 50px;
        }

        .content {
            margin: 0 auto;
            width: 70%;
        }

        &.page {
            align-items: center;

            h1 {
                font-size: 70px;
                line-height: 70px;
            }

            .content {
                width: 80%;
            }

            .breadcrumbs {
                padding: 5px;
                font-size: 16px;

                li {
                    a {
                        color: $text-default;
                    }

                    &::after {
                        color: $text-default;
                    }
                }
            }

            .button {
                display: none;
            }
        }

        p {
            color: $white;
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    &.jumbotron {
        .jumbotronContainer {
            height: auto;
            padding-top: 80px;
            padding-bottom: 40px;
            h1 {
                font-size: 35px;
                line-height: 38px;
            }

            .content {
                width: 100%;
            }
        }
    }
}
