&.header{
    height: 180px;
    border-bottom: 1px solid #AAABB6;
    padding:42px 60px;
    background-color: $white;
    margin-top: 78px;
    h2{
        font-weight: 800;
        font-size: 48px;
        color:$text-default
    }
}