&.article {
    padding-bottom: 8.5%;
    align-items: unset;
    padding: 0px;

    .card-header {
        padding: 0px;
        border-radius: unset;
        border-bottom: none;
    }

    .card-title {
        padding:20px 40px;
        h4 {
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: -0.02em;
            padding: 0px;
            @include maxlines(3);
        }
        p {
            padding: 0px;
            @include maxlines(3);

        }
    }

    .card-img {
        height: 160px;
        width: 100%;
        border-radius: 5px;
        background-image: url("/images/default/article/jumbo.png");
    }
    .cta {
        background-color: transparent;
        color: $grey-600;
        margin: 15px 8px;
        position: absolute;
        bottom: 0px;
        font-weight: 400;
        line-height: 24px;
        i {
            background: $critical;
            padding: 5px 6px 5px 5px;
            border-radius: 50%;
            margin: 0 0 0 10px;
            color: $white;
        }
    }
    .card-img {
        background-size: cover;
    }
    &.placeholder {
        background-image: url("../../images/jumbo.png");
    }
    @import "component.tile.article.overview";
    @import "component.tile.article.faq";
}
