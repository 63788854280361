&.faq {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    p {
        color: $text-default;
    }
    .question {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
    }
    .answer {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        @include maxlines(5);
    }
    &.swiper-slide{
        margin:20px auto;
    }
}

