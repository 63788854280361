&.touchpoint {
    background-color: transparent;
    color: $text-default;
    padding: 0;
    margin: 0;
    display: relative;
    width: 100%;

    .cover {
        grid-row: 3;
        grid-column: 1;
        background-repeat: no-repeat;
        background-position: center left;
    }

    .alert {
        margin: 10px 15px;
        ul {
            padding: 0;
            list-style-type: none;
            li {
                color: $critical;
                font-weight: 400;
            }
        }
    }

    .step {
        grid-row: 3;
        grid-column: 2;
        display: grid;
        gap: 10px;
        padding: 40px 15px;
        grid-template-columns: repeat(6, 1fr);

        > * {
            grid-column: 1/-1;
            text-align: left;
        }

        .steptitle {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            color: $text-default;
            margin-bottom: 0;
        }

        .stepdescription {
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
        }

        p {
            margin: 0;
        }
    }
    .tac_accepted {
        position: relative;
        height: 350px;
        background-color: $grey-500;
        padding: 3% 10% 3% 10%;
        overflow-y: auto;
        .page-content {
            background-color: $white;
            padding: 30px;
        }
        p {
            text-transform: none;
            margin: 0px;
        }
    }

    .photogrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        &.damage {
            grid-template-columns: repeat(2, 1fr);
        }

        // FRECCE PAGINA FOTO ESTERNE VEICOLO

        i {
            position: absolute;

            &.fr {
                top: 0%;
                left: 0%;
                transform: rotate(225deg);
            }

            &.rs {
                top: 0%;
                transform: translate(-50%, 0) rotate(270deg);
                left: 50%;
            }

            &.rr {
                top: 0%;
                right: 0%;
                transform: rotate(315deg);
            }

            &.fv {
                top: 50%;
                left: 0%;
                transform: rotate(180deg);
            }

            &.rv {
                top: 50%;
                right: 0%;
            }

            &.fl {
                bottom: 0%;
                left: 0%;
                transform: rotate(135deg);
            }

            &.ls {
                bottom: 0%;
                transform: translate(-50%, 0) rotate(90deg);
                left: 50%;
            }

            &.rl {
                bottom: 0%;
                right: 0%;
                transform: rotate(45deg);
            }
        }
    }

    @media only screen and (max-width: 991px) {
        h4 {
            font-size: 28px;
            line-height: 32px;
        }

        > p {
            font-size: 20px;
            line-height: 32px;
        }
    }

    @media only screen and (max-width: 767px) {
        h4 {
            font-size: 22px;
            line-height: 30px;
        }

        > p {
            font-size: 16px;
            line-height: 24px;
        }
        .photogrid {
            grid-template-columns: repeat(1, 1fr);
            &.damage {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .tac_accepted {
            padding: 3% 5% 3% 5%;
            .page-content {
                padding: 10px;
            }
        }
    }
}

.thankyoumessage {
    margin: 60px 0;
    padding: 30px;
    background-color: $green;
    color: $surface;
    border-radius: 20px;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;

    a {
        font-weight: 900;
        color: $surface-critical-subdued;
    }
}

.stepmovement {
    display: flex;
    justify-content: end;
}
