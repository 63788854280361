&.partial{
    ol{
        li{
            background-color: $text-default;
            color:$text-default-subdued;
            font-size: 16px;
            strong{
                color: $white;
                font-size: 22px;
            }
            i{
                font-size: 42px;
                padding-bottom: 10px;
                color: $white;
            }
        }
    }
}